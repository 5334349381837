@import "~@neard-packages/react-components/index.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  overscroll-behavior: none;
}

/* Typography */
html[lang="en-US"] .font-primary {
  @apply font-primary-zh;
}

html[lang="en-US"] .font-accent {
  @apply font-accent-zh;
}

html[lang="en-US"] {
  @apply font-accent-zh;
}

html[lang^="zh"] .font-primary {
  @apply font-primary-zh;
}

html[lang^="zh"] .font-accent {
  @apply font-accent-zh;
}

html[lang^="zh"] {
  @apply font-accent-zh;
}

html[lang^="ja"] .font-primary {
  @apply font-primary-ja;
}

html[lang^="ja"] .font-accent {
  @apply font-accent-ja;
}

html[lang^="ja"] {
  @apply font-accent-ja;
}

button:focus {
  outline: none;
}

i {
  @apply font-icon;
}

i[class^="icon-"]:before,
i[class*=" icon-"]:before {
  margin: 0;
}
