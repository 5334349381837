@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?14554035");
  src: url("../font/fontello.eot?14554035#iefix") format("embedded-opentype"),
    url("../font/fontello.woff2?14554035") format("woff2"),
    url("../font/fontello.woff?14554035") format("woff"),
    url("../font/fontello.ttf?14554035") format("truetype"),
    url("../font/fontello.svg?14554035#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?14554035#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-chat:before {
  content: "\e800";
} /* '' */
.icon-chatselected:before {
  content: "\e801";
} /* '' */
.icon-explore:before {
  content: "\e802";
} /* '' */
.icon-exploreselected:before {
  content: "\e803";
} /* '' */
.icon-notice:before {
  content: "\e804";
} /* '' */
.icon-noticeselected:before {
  content: "\e805";
} /* '' */
.icon-profile:before {
  content: "\e806";
} /* '' */
.icon-profileselected:before {
  content: "\e807";
} /* '' */
.icon-group:before {
  content: "\e808";
} /* '' */
.icon-public:before {
  content: "\e809";
} /* '' */
.icon-arrowback:before {
  content: "\e80a";
} /* '' */
.icon-arrowdown:before {
  content: "\e80b";
} /* '' */
.icon-arrownext:before {
  content: "\e80c";
} /* '' */
.icon-arrowup:before {
  content: "\e80d";
} /* '' */
.icon-radio_selected:before {
  content: "\e80e";
} /* '' */
.icon-radio:before {
  content: "\e80f";
} /* '' */
.icon-add:before {
  content: "\e810";
} /* '' */
.icon-addgroup:before {
  content: "\e811";
} /* '' */
.icon-send_by:before {
  content: "\e812";
} /* '' */
.icon-flash_auto:before {
  content: "\e813";
} /* '' */
.icon-close:before {
  content: "\e814";
} /* '' */
.icon-comment:before {
  content: "\e815";
} /* '' */
.icon-commentfill:before {
  content: "\e816";
} /* '' */
.icon-copy:before {
  content: "\e817";
} /* '' */
.icon-createpost:before {
  content: "\e818";
} /* '' */
.icon-edit:before {
  content: "\e819";
} /* '' */
.icon-profile_admin:before {
  content: "\e81a";
} /* '' */
.icon-fire:before {
  content: "\e81b";
} /* '' */
.icon-flash:before {
  content: "\e81c";
} /* '' */
.icon-gallery:before {
  content: "\e81d";
} /* '' */
.icon-groupselected:before {
  content: "\e81e";
} /* '' */
.icon-groupmanage:before {
  content: "\e81f";
} /* '' */
.icon-groupnotifications:before {
  content: "\e820";
} /* '' */
.icon-groupsuggested:before {
  content: "\e821";
} /* '' */
.icon-heart:before {
  content: "\e822";
} /* '' */
.icon-hearthill:before {
  content: "\e823";
} /* '' */
.icon-info:before {
  content: "\e824";
} /* '' */
.icon-language:before {
  content: "\e825";
} /* '' */
.icon-block:before {
  content: "\e826";
} /* '' */
.icon-camera:before {
  content: "\e827";
} /* '' */
.icon-location:before {
  content: "\e828";
} /* '' */
.icon-lock:before {
  content: "\e829";
} /* '' */
.icon-member:before {
  content: "\e82a";
} /* '' */
.icon-memberrequest:before {
  content: "\e82b";
} /* '' */
.icon-mute:before {
  content: "\e82c";
} /* '' */
.icon-mutefill:before {
  content: "\e82d";
} /* '' */
.icon-myprofile:before {
  content: "\e82e";
} /* '' */
.icon-newchat:before {
  content: "\e82f";
} /* '' */
.icon-onlinestatus:before {
  content: "\e830";
} /* '' */
.icon-playvideo:before {
  content: "\e831";
} /* '' */
.icon-gps:before {
  content: "\e832";
} /* '' */
.icon-reply:before {
  content: "\e833";
} /* '' */
.icon-report:before {
  content: "\e834";
} /* '' */
.icon-search:before {
  content: "\e835";
} /* '' */
.icon-settings:before {
  content: "\e836";
} /* '' */
.icon-feedselected:before {
  content: "\e837";
} /* '' */
.icon-share:before {
  content: "\e838";
} /* '' */
.icon-remove:before {
  content: "\e839";
} /* '' */
.icon-profile_owner:before {
  content: "\e83a";
} /* '' */
.icon-link:before {
  content: "\e83b";
} /* '' */
.icon-time:before {
  content: "\e83c";
} /* '' */
.icon-uploadimage:before {
  content: "\e83d";
} /* '' */
.icon-description:before {
  content: "\e83e";
} /* '' */
.icon-leavegroup:before {
  content: "\e83f";
} /* '' */
.icon-addwithcircle:before {
  content: "\e840";
} /* '' */
.icon-createfeed:before {
  content: "\e841";
} /* '' */
.icon-feed:before {
  content: "\e842";
} /* '' */
.icon-uploadvideo:before {
  content: "\e843";
} /* '' */
.icon-locationfill:before {
  content: "\e844";
} /* '' */
.icon-feedback:before {
  content: "\e845";
} /* '' */
.icon-gif:before {
  content: "\e846";
} /* '' */
.icon-createpost-1:before {
  content: "\e847";
} /* '' */
.icon-infofill:before {
  content: "\e848";
} /* '' */
.icon-memberrequesttick:before {
  content: "\e849";
} /* '' */
.icon-menu:before {
  content: "\e84a";
} /* '' */
.icon-more:before {
  content: "\e84b";
} /* '' */
.icon-qrcode:before {
  content: "\e84c";
} /* '' */
.icon-scanqrcode:before {
  content: "\e84d";
} /* '' */
.icon-showpreview:before {
  content: "\e84e";
} /* '' */
.icon-soundalertsettings:before {
  content: "\e84f";
} /* '' */
.icon-sub-chatroom:before {
  content: "\e850";
} /* '' */
.icon-pin:before {
  content: "\e851";
} /* '' */
.icon-verifyprofile:before {
  content: "\e852";
} /* '' */
.icon-hashtag:before {
  content: "\e853";
} /* '' */
.icon-searchhashtag:before {
  content: "\e854";
} /* '' */
.icon-searchprofile:before {
  content: "\e855";
} /* '' */
.icon-facebook:before {
  content: "\e856";
} /* '' */
.icon-ig:before {
  content: "\e857";
} /* '' */
.icon-managefollow:before {
  content: "\e858";
} /* '' */
.icon-youtube:before {
  content: "\e859";
} /* '' */
.icon-coupon:before {
  content: "\e85a";
} /* '' */
.icon-membership:before {
  content: "\e85b";
} /* '' */
.icon-edit_reward_items:before {
  content: "\e85c";
} /* '' */
.icon-manual_input:before {
  content: "\e85d";
} /* '' */
.icon-redeem_history:before {
  content: "\e85e";
} /* '' */
.icon-followpushoff:before {
  content: "\e85f";
} /* '' */
.icon-followpushno:before {
  content: "\e860";
} /* '' */
.icon-pin-1:before {
  content: "\e861";
} /* '' */
.icon-upgrade:before {
  content: "\e862";
} /* '' */
.icon-voicemessage:before {
  content: "\e863";
} /* '' */
.icon-calendar:before {
  content: "\e864";
} /* '' */
.icon-my_qr_code:before {
  content: "\e865";
} /* '' */
.icon-filter:before {
  content: "\e866";
} /* '' */
.icon-direction:before {
  content: "\e867";
} /* '' */
.icon-profile_restriction:before {
  content: "\e868";
} /* '' */
.icon-hidden:before {
  content: "\e869";
} /* '' */
.icon-blacklist:before {
  content: "\e86a";
} /* '' */
.icon-revokelink:before {
  content: "\e86b";
} /* '' */
.icon-joined_group:before {
  content: "\e86c";
} /* '' */
.icon-switch:before {
  content: "\e86d";
} /* '' */
.icon-interests:before {
  content: "\e86e";
} /* '' */
.icon-reload:before {
  content: "\e86f";
} /* '' */
.icon-keyboard:before {
  content: "\e870";
} /* '' */
.icon-multiple_choice:before {
  content: "\e871";
} /* '' */
.icon-schedule:before {
  content: "\e872";
} /* '' */
.icon-voting:before {
  content: "\e873";
} /* '' */
.icon-login:before {
  content: "\e874";
} /* '' */
.icon-question:before {
  content: "\e875";
} /* '' */
.icon-pinned:before {
  content: "\e876";
} /* '' */
.icon-support_inbox:before {
  content: "\e877";
} /* '' */
.icon-get_sticker:before {
  content: "\e878";
} /* '' */
.icon-sound_funny:before {
  content: "\e879";
} /* '' */
.icon-sound_wave:before {
  content: "\e87a";
} /* '' */
.icon-sound_on:before {
  content: "\e87b";
} /* '' */
.icon-pause:before {
  content: "\e87c";
} /* '' */
.icon-message_send:before {
  content: "\e87d";
} /* '' */
.icon-admin_key:before {
  content: "\e87e";
} /* '' */
.icon-whatsapp:before {
  content: "\e87f";
} /* '' */
.icon-email:before {
  content: "\e880";
} /* '' */
.icon-permalink:before {
  content: "\e881";
} /* '' */
.icon-twitter:before {
  content: "\e882";
} /* '' */
.icon-facebook-1:before {
  content: "\e883";
} /* '' */
.icon-add_media:before {
  content: "\e884";
} /* '' */
.icon-bookmark:before {
  content: "\e885";
} /* '' */
.icon-sticker:before {
  content: "\e886";
} /* '' */
.icon-time_limit_archive:before {
  content: "\e887";
} /* '' */
.icon-time_limit:before {
  content: "\e888";
} /* '' */
.icon-voicefilter:before {
  content: "\e889";
} /* '' */
.icon-invite_code:before {
  content: "\e88a";
} /* '' */
.icon-play:before {
  content: "\e88b";
} /* '' */
.icon-logo_facebook:before {
  content: "\e88c";
} /* '' */
.icon-logo_apple:before {
  content: "\e88d";
} /* '' */
.icon-download:before {
  content: "\e88e";
} /* '' */
.icon-message_preview:before {
  content: "\e88f";
} /* '' */
.icon-privacy:before {
  content: "\e890";
} /* '' */
.icon-bugs:before {
  content: "\e891";
} /* '' */
.icon-terms:before {
  content: "\e892";
} /* '' */
.icon-mission:before {
  content: "\e893";
} /* '' */
.icon-sort_by:before {
  content: "\e894";
} /* '' */
.icon-man:before {
  content: "\e895";
} /* '' */
.icon-robot:before {
  content: "\e896";
} /* '' */
.icon-squirrel:before {
  content: "\e897";
} /* '' */
.icon-crop:before {
  content: "\e898";
} /* '' */
.icon-send_gifts:before {
  content: "\e899";
} /* '' */
.icon-warning:before {
  content: "\e89a";
} /* '' */
.icon-restore:before {
  content: "\e89b";
} /* '' */
.icon-key_filled:before {
  content: "\e89c";
} /* '' */
.icon-key_outline:before {
  content: "\e89d";
} /* '' */
.icon-vote_end_time:before {
  content: "\e89e";
} /* '' */
.icon-not_encrypted:before {
  content: "\e89f";
} /* '' */
.icon-device_desktop:before {
  content: "\e8a0";
} /* '' */
.icon-device_mobile:before {
  content: "\e8a1";
} /* '' */
.icon-device_tablet:before {
  content: "\e8a2";
} /* '' */
.icon-remove_fill:before {
  content: "\e8a3";
} /* '' */
.icon-sound_on_fill:before {
  content: "\e8a4";
} /* '' */
.icon-unpin_fill:before {
  content: "\e8a5";
} /* '' */
.icon-unpin:before {
  content: "\e8a6";
} /* '' */
.icon-clock:before {
  content: "\e8a7";
} /* '' */
.icon-remove_bookmark:before {
  content: "\e8a8";
} /* '' */
.icon-emoji:before {
  content: "\e8a9";
} /* '' */
.icon-troubleshot:before {
  content: "\e8aa";
} /* '' */
.icon-gif-1:before {
  content: "\e8ab";
} /* '' */
.icon-gifwording:before {
  content: "\e8ac";
} /* '' */
.icon-login_activity:before {
  content: "\e8ad";
} /* '' */
.icon-flash_off:before {
  content: "\e8ae";
} /* '' */
.icon-telegram:before {
  content: "\e8af";
} /* '' */
.icon-arrow_mouseover:before {
  content: "\e8b0";
} /* '' */
.icon-erase:before {
  content: "\e8b1";
} /* '' */
.icon-moderators_remove:before {
  content: "\e8b2";
} /* '' */
.icon-moderators:before {
  content: "\e8b3";
} /* '' */
.icon-unmute:before {
  content: "\e8b4";
} /* '' */
.icon-reply_filled:before {
  content: "\e8b5";
} /* '' */
.icon-expand:before {
  content: "\e8b6";
} /* '' */
.icon-nsfw:before {
  content: "\e8b7";
} /* '' */
.icon-empty:before {
  content: "\e8b8";
} /* '' */
.icon-forward:before {
  content: "\e8b9";
} /* '' */
